// ** TITLE TYPE CONSTANTS
import { SUB_PAGE_TITLE, HIDDEN_TITLE, BLANK_TITLE, BLANK_WITH_TITLE, HOME_TITLE, MAIN_PAGE_TITLE } from '@core/constants/Title';
// ** Layout TYPE CONSTANTS
import { DEFAULT_LAYOUT, BLANK_MOBILE_LAYOUT } from '@core/constants/Layout';

const CoverLetterRoutes = [
	{
		path: '/cover-letter',
		exact: true,
		layout: DEFAULT_LAYOUT,
		isUserDashboard: true,
		meta: {
			title: { text: 'My Cover Letters', type: SUB_PAGE_TITLE },
            protected: true,
		}
	},
    {
		path: '/cover-letter/new',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'My Cover Letters', type: SUB_PAGE_TITLE },
            protected: true,

		}
	},
    {
		path: '/cover-letter/preview/[id]',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'My Cover Letters', type: SUB_PAGE_TITLE },
            protected: true,
		}
	},
    {
		path: '/cover-letter/edit/[id]',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'My Cover Letters', type: SUB_PAGE_TITLE },
            protected: true,
		}
	},
	
];

export default CoverLetterRoutes;
